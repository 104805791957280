import { useState, useEffect } from 'react';
import { ReactComponent as Arrow } from '../assets/down-arrow.svg';

export default function DownArrow() {
    var [opacity, setOpacity] = useState(1);
    useEffect(() => {
        var changeOpacity = (e) => setOpacity(1 - window.scrollY / 100);
        window.addEventListener("scroll", changeOpacity, false);
        
        return () => window.removeEventListener("scroll", changeOpacity, false);
    });

    return (
        <Arrow style={{opacity: opacity}} className="fixed bottom-10 inset-x-0 w-full h-8 transform to-beige animate-bounce" />
    );
}
