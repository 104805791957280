import { ScrollRestoration, Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Root() {
    return (<>
        <ScrollRestoration />
        <div className="bg-[#140f0f] bg-[url(./assets/xenia-logo.png)] bg-no-repeat bg-center bg-fixed w-full min-h-[90vh]">
            <Header />
            <main className="w-11/12 max-w-6xl m-auto pt-14 pb-40 font-body text-orange-200 text-2xl">
                <Outlet />
            </main>
        </div>
        <Footer />
    </>);
}
