export default function Website() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                My Website!
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <p>
                    Woah! You're on my website right now!
                    <br />
                    So meta :3
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    Originally, I wrote my entire website using Jekyll and SCSS. This was
                    entirely driven by the fact that GitHub Pages allowed automatic building,
                    publishing, and hosting for websites in this format. In addition, I had much less of
                    an idea what I was doing, and over time, my inexperience contributed to
                    a large amount of technical debt.
                </p>
                <p className="mb-5">
                    I eventually changed over computers to primarily use the Fox-PC, and
                    found that the new environment made my old website locally untestable
                    (or at the very least, I couldn't figure out how to fix all my
                    dependency issues). That was quite the issue.
                </p>
                <p>
                    Eventually, I decided to
                    recreate the entire site from the ground up, using a framework that was more
                    robust than Jekyll. This solution fixed my untestability issue,
                    and removed much of the built-up technical debt, but it did take a few weeks to
                    remake this admittedly small site.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    I chose to use React for the rewrite because not only did
                    I have experience with it, I was also looking to learn how
                    to use React Router, a task that I had been putting off for
                    months.
                </p>
                <p>
                    In addition, I also switched from SCSS to Tailwind CSS. I had
                    seen a former friend using it, and was curious as to how it
                    performed and how she used it.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p className="mb-5">
                    I tried to make the content identical to how it was before, updating only
                    a few project listings that contained outdated information. Stylistically,
                    I tried to make the colors pop more and make for a better viewing experience,
                    aided by Tailwind CSS (which I really enjoy, to be honest).
                </p>
                <p className="mb-5">
                    There is much less technical debt (I would be a fool to state
                    that there isn't any), and I shifted my flagship website to the more modern
                    and robust React, so I would consider this endeavour a success!
                </p>
                <p>
                    As an added bonus, everyone I've talked to prefers the restylised version!
                    I'm starting to need to look for internships and jobs in the upcoming college
                    years, so hopefully the now prettier website will help in that journey.
                </p>
            </div>
        </div>
    );
}
