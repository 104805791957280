import ExternalLink from '../../components/ExternalLink';

export default function TTPlus() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Treacherous Trials+
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://foxsylv.itch.io/treacheroustrialsplus" className="hover:underline focus:underline">
                    <p className="mb-5">
                        https://foxsylv.itch.io/treacheroustrialsplus
                    </p>
                </ExternalLink>
                <p>
                    A (very difficult) mod of GD Colon's game,
                    <ExternalLink href="http://gdcolon.com/tt" className="hover:underline focus:underline mx-1">
                        Treacherous Trials.
                    </ExternalLink>
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    As a speedrunner for Treacherous Trials (and as of writing, the
                    any% world record holder by a significant margin), I originally wanted to
                    look at the source code and push the game to its limits. That vision of
                    determination eventually evolved into the fan-made maps that explore
                    the unused tech from the original game with a difficulty increase
                    to match the precision of some tricks.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    To make the fan maps, first I needed to either recreate the entire
                    game from scratch, or rip the project and decompile it back to
                    the original Unity project. This second option is both easier and
                    finishes with a more faithful recreation, so that's what I did.
                </p>
                <p className="mb-5">
                    I used
                    <ExternalLink href="https://github.com/AssetRipper/AssetRipper" className="hover:underline focus:underline mx-1">
                        Unity AssetRipper
                    </ExternalLink>
                    to get most of the requisite files. A key issue,
                    however, was that all non-user created scripts (such as any text and
                    the camera movement from Cinemachine, for example) were completely wiped,
                    requiring me to remake all of them. In addition, some of the files ripped
                    had mysterious bugs not present in the original game requiring some
                    tinkering to make function.
                </p>
                <p>
                    As for level design, I tried to keep the gameplay somewhat balanced,
                    although the difficulty does increase quite a lot over the course of the game. I
                    also attempted to keep the theme of each level the same as the base game,
                    even as the new tech was introduced in what is hopefully a fair manner. While there
                    are a few levels I'm not the biggest fan of (namely levels 17 and 20), overall I'm
                    quite satisfied with how it all turned out.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p className="mb-5">
                    While unintentional, I learned a good deal about Unity and how to put together
                    a game in it. I'm not sure if I'll ever make my own game using it rather than
                    just modifying an existing game, but if it particularly interests me one day
                    for some reason, I might give it a shot!
                </p>
                <p>
                    I shared it with the speedrunning discord for Treacherous Trials, and I
                    think it had a generally positive reception. To be fair, the discord has a
                    whole like, ten memebers max, so I really put in way too much effort given
                    the target audience haha. And also nobody besides me managed to get past
                    level 14 for several months after the mod released,
                    so mayyybe I made it a bit too difficult. Oops!
                </p>
            </div>
        </div>
    );
}
