import ExternalLink from '../../components/ExternalLink';
import { Link } from "react-router-dom";

export default function VFox() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                VFox Bot
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <Link to="/vfox" className="header text-3xl hover:underline focus:underline">
                    <p className="mb-5">
                        Invite Page
                    </p>
                </Link>
                <p>
                    A game about collecting foxes
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    The main idea behind the bot is to turn the usual
                    idle/grind gameplay loop slightly on its head. In usual
                    games, your power is constant and prices increase
                    exponentially. In VFox, prices increase linearly, and your
                    power <em>decreases</em> exponentially. This achieves the same
                    growth idea, but in a novel way.
                </p>
                <p className="mb-5">
                    I actually had the idea for a game like this when I was in
                    middle school, back when
                    <ExternalLink href="https://https://orteil.dashnet.org/cookieclicker/" className="hover:underline focus:underline mx-1">
                    Cookie Clicker
                    </ExternalLink>
                    was super popular. The idea stewed in the back of my head
                    for a while, until I finally made it a reality many years
                    later.
                </p>
                <p>
                    The catalyst for this decision was that I wanted to learn
                    how databases work, and making a game that stores complex
                    player data is a great way to figure things out.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    I used
                    <ExternalLink href="https://discord.js.org/" className="hover:underline focus:underline mx-1">
                        Discord.js
                    </ExternalLink>
                    to make the bot. It was what was almost universally
                    recommended to me by other people who have also made
                    discord bots. There were times I wished that the
                    documentation was more descriptive, though.
                </p>
                <p className="mb-5">
                    To store user data, I use
                    <ExternalLink href="https://www.mongodb.com/" className="hover:underline focus:underline ml-1">
                        MongoDB
                    </ExternalLink>,
                    using
                    <ExternalLink href="https://mongoosejs.com/" className="hover:underline focus:underline ml-1">
                        Mongoose
                    </ExternalLink>.
                    Ideally, I would self-host the database, but
                    <ExternalLink href="https://www.mongodb.com/" className="hover:underline focus:underline mx-1">
                        MongoDB Atlas
                    </ExternalLink>
                    has a free tier with enough storage for a few hundred
                    users, which I felt was sufficient for now. I doubt the bot
                    will ever actually get popular, so there was no need to
                    make my life more difficult by self-hosting the database
                    and needing to worry about backups/uptime/etc.
                </p>
                <p>
                    I do self-host the actual bot itself, though. I don't
                    currently have a second computer or server, so it is
                    currently just running in the background of my main PC.
                    This is a nonoptimal solution, but it's the best I could
                    come up with given my hardware limitations.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p className="mb-5">
                    Currently, the bot is online for anyone to engage with.
                    You can check it out in the official server, or add it to
                    your own discord servers with the
                    <Link to="/vfox" className="hover:underline focus:underline ml-1">
                        bot invite link
                    </Link>.
                </p>
                <p className="mb-10">
                    All in all, this is probably the biggest, most complex
                    project I've ever made. Because not only are there many
                    many components to the game itself, but there's also the
                    entire ordeal of balancing everything. (For the balancing,
                    the beta testers have been very very helpful. Thank you!).
                    I am glad at how it turned out, and I hope that people
                    enjoy playing VFox.
                </p>
                <p>
                    The VFox image is taken from
                    <ExternalLink href="https://www.reddit.com/r/celestegame/comments/navq3o/madeline_and_badeline_but_theyre_foxes/" className="hover:underline focus:underline ml-1">
                        a reddit post
                    </ExternalLink>. I couldn't get into contact with the
                    original artist, so if you <em>are</em> the artist and do
                    not wish for me to use your image, please
                    <Link to="/contact" className="hover:underline focus:underline ml-1">
                        contact me
                    </Link>!!
                </p>
            </div>
        </div>
    );
}
