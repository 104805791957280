import { useLocation, Link } from 'react-router-dom';
import { useState } from 'react';

export default function Header() {
    const tabNonselected = "border-transparent hover:border-sky-600 focus:border-sky-600 hover:bg-blue-500 focus:bg-blue-500 hover:underline underline-offset-2";
    const tabSelected = "border-sky-600 bg-blue-500";
    const nameNonselected = "hover:text-orange-600 focus:text-orange-600 hover:underline focus:underline";
    const nameSelected = "underline";

    let location = useLocation();
    
    const subheaderLinks = [
        "projects", "gaming", "contact"
    ].map(link => <Link to={"/" + link}
                        key={link}
                        className={"px-9 border-solid border-3 rounded-sm my-[-3px] text-2xl " + (location.pathname.split("/")[1] === link ? tabSelected : tabNonselected)}>
                      <span className="md:hidden">
                          {"• "}
                      </span>
                      {"~/" + link}
                  </Link>);

    let [isSubOpen, setIsSubOpen] = useState(false);

    return (
        <header className="font-body sticky top-0 z-50">
            {/* Upper Header */}
            <div className="bg-sky-400 py-1">
                <p className="float-right px-[0.5rem] mr-2 border-sky-600 border-3 rounded-xl text-5xl md:hidden cursor-pointer" onClick={() => setIsSubOpen(!isSubOpen)}>
                    ≡
                </p>
                <p className="max-w-7xl m-auto px-3 py-1 text-3xl">
                    <span className="hidden sm:inline">
                        you@
                    </span>
                    <Link to="" className={"mx-1 header text-orange-500 text-shadow underline-offset-3 outline-0 " + (location.pathname === "/" ? nameSelected : nameNonselected)}>
                        FoxSylv
                    </Link>
                    .dev
                    <span className="hidden sm:inline">
                        :$ cd
                        <span className="mx-1 animate-[blink_1.4s_linear_1s_infinite]">
                            _
                        </span>
                    </span>
                </p>
            </div>

            {/* Sub-Header */}
            <div className={"bg-sky-500 border-solid border-sky-600 border-y-3 " + (isSubOpen ? "block" : "hidden") + " md:block"}>
                <div className="max-w-7xl m-auto flex flex-col md:flex-row">
                    {subheaderLinks}
                </div>
            </div>
        </header>
    );
}
