export default function Fox0() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Fox-0 Minecraft CPU
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <div className="relative h-0 pb-[56.25%]">
                    <iframe src="https://www.youtube-nocookie.com/embed/OsTBxtSaTeI" title="Youtube video player" className="absolute top-0 left-0 w-full h-full"></iframe>
                </div>
            </div>

            <h2 className="header text-4xl mb-10">
                Instruction Set
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-10">
                    The instruction set is divided up into four main classes of instructions. Register
                    numbers are in 4-bit chunks (even though only the least 3 bits have physical
                    registers available for use).
                </p>
                <p className="mb-5">
                    00 [opcode] [reg1] [reg2]
                </p>
                <p className="mb-5">
                    The instruction for passing data through the ALU. Reg2 is overwritten with the result. Below is a list of all accepted opcodes:
                </p>
                <ol className="text-left mb-10">
                    <li /> 0000 - NOP
                    <li /> 0001 - NOR
                    <li /> 0010 - SUB
                    <li /> 0011 - DEC
                    <li /> 0100 - ADD
                    <li /> 0101 - SHR (by one)
                    <li /> 0110 - XOR
                    <li /> 0111 - NAND
                    <li /> 1000 - AND
                    <li /> 1001 - XNOR
                    <li /> 1010 - ADC (always with CF = 1)
                    <li /> 1011 - NEG
                    <li /> 1100 - INC
                    <li /> 1101 - SAR (by one)
                    <li /> 1110 - OR
                    <li /> 1111 - NOT
                </ol>
                <p className="mb-5">
                    11 [reg] [immediate]
                </p>
                <p className="mb-10">
                    Immediates an 8-bit value into the given register.
                </p>
                <p className="mb-5">
                    10 [condition] [target location]
                </p>
                <p className="mb-5">
                    Moves the instruction pointer to the given 8-bit target location if the condition is satisfied.
                    It is strongly recommended to leave three NOP operations after any jump instruction
                    unless you know what you're doing.
                </p>
                <ol className="text-left mb-5">
                    <li /> 0001 - JZ
                    <li /> 1001 - JNZ
                    <li /> 0010 - JS
                    <li /> 1010 - JNS
                    <li /> 0100 - JC
                    <li /> 1100 - JNC
                </ol>
                <p className="mb-10">
                    Notice that the three least significant bits correspond to flags and the leading bit acts as negation.
                    When multiple flags are selected, only one condition needs to be satisfied to jump.
                </p>
                <p>
                    01 0000 [reg1] [reg2] - MOV
                </p>
                <p>
                    01 1111 0000 0000 - HLT
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Example Programs
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    A copy of the ROM used in the showcase is available on ORE (mc.openredstone.org).
                    Collatz starts at address 0x05 and Fibonacci starts at address 0x30. Address 0x01 is a
                    jump that can take the IP directly to the start of Fibonacci with a change in repeater placement
                    (the top repeater at the first ROM address).
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    This was a fun little project. The showcase was also the first time I actually really TRIED to edit
                    something, and I think it came out alright. I learned a lot about computer logic and architecture
                    during this, and of course got a lot better at redstone. I would recommend trying it if you're interested,
                    because the initial intimidation everyone feels is honestly somewhat unfounded, so long as you continually
                    try to improve. ^w^
                </p>
            </div>
        </div>
    );
}
