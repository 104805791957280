import { Link } from 'react-router-dom';
import ExternalLink from './ExternalLink';
import { ReactComponent as GHLogo } from '../assets/site-icons/github-icon.svg';
import { ReactComponent as DCLogo } from '../assets/site-icons/discord-icon.svg';
import xenia from '../assets/xenia.png';


function footerLink(href, title, img, name) {
    return (
        <ExternalLink href={href} title={title} className="mx-1.5 hover:underline">
            {img}
            <span className="ml-1">
                {name}
            </span>
        </ExternalLink>
    );
}


export default function Footer() {
    return (
        <footer className="w-full bg-sky-400 border-t-5 border-sky-600 font-body relative">
            <p className="max-w-7xl m-auto px-3 py-14 text-xl leading-9">
                Made by FoxSylv! nya nya foxgirl uwu {"<3"}
                <br />
                {footerLink("https://github.com/FoxSylv", "Github", <GHLogo className="inline -translate-y-0.5"/>, "FoxSylv")}
                •
                {footerLink("https://discord.com/users/294814074670284800", "Discord", <DCLogo className="inline h-[1.125rem] -translate-y-0.5"/>, "foxsylv")}
                •
                <Link to="/contact" title="Contact Me!" className="mx-1 hover:underline">more!</Link>
                <br /><br />
                Xenia the Linux Fox by <ExternalLink href="https://twitter.com/cathodegaytube/status/1197227512075411456" className="hover:underline">@cathodegaytube</ExternalLink>
                <br />
                Background Fox drawn by <ExternalLink href="https://twitter.com/QuestForTori/status/1279290356387872769" className="hover:underline">@QuestForTori</ExternalLink>
            </p>
            <ExternalLink href="https://twitter.com/cathodegaytube/status/1197227512075411456"
                          title="Xenia the Linux Fox! I'm using her as my mascot! ^w^"
                          className="float-right absolute right-10 bottom-0 scale-[0.6] origin-bottom-right hover:-translate-x-3 transition duration-500 ease hidden md:block">
                <img src={xenia} className="" alt=""/>
            </ExternalLink>
        </footer>
    );
}
