import ExternalLink from '../../components/ExternalLink';

export default function Reminders() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Reminders
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://reminders.foxsylv.dev" className="hover:underline focus:underline">
                    <p className="mb-5">
                        https://reminders.foxsylv.dev
                    </p>
                </ExternalLink>
                <p>
                    A tool to help with remembering
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    In my room, I have a whiteboard that I typically use to store my to-do list
                    and use as a general place to write and do work/calculatons. Typically, the
                    to-do list part would use a significant amount of the board, hampering the
                    amount of work I could do on it concurrently. My solution was to just put my
                    to-do list online, leading to
                    <ExternalLink href="https://foxsylv1.github.io/reminders" className="hover:underline focus:underline mx-1">
                        Reminders
                    </ExternalLink>.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    Internally, the notes are stored in an XML tree for ease of access.
                    XML is used for the sole purpose that it is a built-in tree, making
                    it so I didn't need to recreate any data structure.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    Although relatively simple, this project did teach me a few important things.
                    Firstly, how to use input tags in HTML to get user input (previously in letter
                    getter, I just tracked keypresses). Secondly, I learned about XML and how to use it,
                    along with more broadly, more about the inner workings of how website data is
                    internally stored in the process.
                </p>
            </div>
        </div>
    );
}
