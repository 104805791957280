import { Link } from 'react-router-dom';

export default function ProjectBox(props) {
    const project = props.project;
    const link = (props.linkOverride === undefined ? project.link : props.linkOverride);

    return (
        <Link to={link} className="block box text-center w-full max-w-xs m-4 hover:shadow-light focus:shadow-light transition-shadow">
            <h3 className="header text-3xl">
                {project.name}
            </h3>
            <div className="w-60 h-60 overflow-hidden mx-auto mt-5 mb-3">
                {project.thumbnail}
            </div>
            <p>
                {project.description}
            </p>
        </Link>
    );
}
