import ExternalLink from '../../components/ExternalLink';

export default function NonBeGone() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                NonBeGone
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://www.chattriggers.com/modules/v/NonBeGone" className="hover:underline focus:underline">
                    <p className="mb-5">
                        https://www.chattriggers.com/modules/v/NonBeGone
                    </p>
                </ExternalLink>
                <p>
                    A minecraft mod that deletes the messages of any Hypixel Skyblock users whose Skyblock level is below a customizable threshold.
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    My boyfriend plays a lot of Hypixel Skyblock, and was lamenting
                    how most of the messages in chat from extremely low level players
                    were just begging for items. He suggested that I make a mod that
                    prevents those messages from appearing client-side.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    I used
                    <ExternalLink href="https://www.chattriggers.com" className="hover:underline focus:underline mx-1">
                        ChatTriggers
                    </ExternalLink>
                    to make the mod, for ease of creation and usage.
                    <ExternalLink href="https://www.chattriggers.com" className="hover:underline focus:underline mx-1">
                        ChatTriggers
                    </ExternalLink>
                    uses javascript to compose the mod, so I had experience with the
                    language used.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    The mod is submitted to the
                    <ExternalLink href="https://www.chattriggers.com" className="hover:underline focus:underline mx-1">
                        ChatTriggers
                    </ExternalLink>
                    modules listing, and is verified for anyone to download and use.
                </p>
            </div>
        </div>
    );
}
