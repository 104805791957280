import { useRouteLoaderData } from 'react-router-dom';
import ProjectBox from '../components/ProjectBox';

export default function Projects() {
    const projects = useRouteLoaderData("root");

    return (<>
        <h1 className="header mb-20">
            My Projects
        </h1>
        <div className="flex flex-wrap justify-center">
            {projects.map((project) => <ProjectBox project={project} key={project.link} />)}
        </div>
    </>);
}
