import { useParams, useRouteLoaderData } from 'react-router-dom';
import ErrorPage from './ErrorPage';

export default function ProjectPage() {
    var {projectLink} = useParams();
    var projects = useRouteLoaderData("root");
    var project = projects.find((e) => e.link === projectLink);
    var route = (project === undefined ? <ErrorPage is404="true" /> : project.route)

    return (<>
        {route}
    </>);
}
