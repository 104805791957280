import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from './routes/Root';
import ErrorPage from './routes/ErrorPage';

import Index from './routes/Index';
import Gaming from './routes/Gaming';
import Contact from './routes/Contact';
import Projects from './routes/Projects';
import ProjectPage from './routes/ProjectPage';
import TTplusRedirect from './routes/TTplusRedirect';
import VFoxInvite from './routes/VFoxInvite';

import { getProjects } from './data/getProjects.js';


const router = createBrowserRouter([{
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: async () => getProjects(),
    id: "root",
    children: [{
        path: "*",
        errorElement: <ErrorPage />,
        children: [
            {index: true, element: <Index />},
            {path: "gaming", element: <Gaming />},
            {path: "contact", element: <Contact />},
            {path: "projects", element: <Projects />},
            {path: "projects/:projectLink", element: <ProjectPage />},
            {path: "tt+", element: <TTplusRedirect />},
            {path: "vfox", element: <VFoxInvite />},
            {path: "*", element: <ErrorPage is404="true" />}
        ]
    }]
}]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
