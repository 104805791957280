import ExternalLink from '../components/ExternalLink';
import { ReactComponent as YTLogo } from '../assets/site-icons/youtube-icon.svg';
import { ReactComponent as SRCLogo } from '../assets/site-icons/speedrun-icon.svg';


function showcase(name, videos) {
    var videoBoxes = videos.map((video) => videoBox(video.title, video.link));

    return (<>
        <h1 className="header mb-5">
            {name}
        </h1>
        <div className="flex flex-wrap justify-center mb-20">
            {videoBoxes}
        </div>
    </>);
}

function videoBox(title, link) {
    return (
        <div className="w-full md:w-[40%] box m-8" key={link}>
            <h2 className="header text-3xl mb-10">
                {title}
            </h2>
            <div className="relative h-0 pb-[56.25%]">
                <iframe src={link} className="absolute top-0 left-0 w-full h-full" title="Youtube video player"></iframe>
            </div>
        </div>
    );
}


function gamingLink(name, img, link) {
    return (
        <ExternalLink href={link} title={name} className="mx-2 hover:underline focus:underline">
            <span className="mr-0.5">
                {img}
            </span>
            {name}
        </ExternalLink>
    );
}



export default function Gaming() {
    return (<>
        {showcase("World Records", [
            {"title": "Letter Quest", "link": "https://www.youtube-nocookie.com/embed/OnJiVG9r54o"},
            {"title": "Treacherous Trials", "link": "https://www.youtube-nocookie.com/embed/FqQKJ-8_eRE"}
        ])}
        <p>
            For a full list of accomplishments, check out my 
            {gamingLink("Youtube", <YTLogo className="to-beige inline -translate-y-0.5" />, "https://www.youtube.com/channel/UCj7lR9rm06lCxE4kxkJeECA")}
            channel, and visit my
            {gamingLink("Speedrun.com", <SRCLogo className="to-beige inline" />, "https://www.speedrun.com/user/FoxSylv")}
            page!
        </p>
    </>);
}
