export default function FoxPC() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Fox-PC
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <p>
                    My real-life workstation!
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    My family needed a new computer, and I noticed that for
                    their desired product, it would be more beneficial if I
                    graduated from my old laptop to an actual PC, with my old
                    laptop becoming the new family computer.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Component List
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <ul className="text-left">
                    <li /> Meshify C Solid
                    <li /> Gigabyte X570 Aorus Elite Wifi
                    <li /> AMD Ryzen 5 5600
                    <li /> Be Quiet! Arctic Freezer 34 eSports Duo
                    <li /> AMD Radeon RX 6650 XT (bought from Sapphire)
                    <li /> Crucial P3 2280 1TB SSD
                    <li /> Corsair Vengeance RGB Pro 2x16 GB (2133 MHz)
                    <li /> Seasonic 650W Focus GX
                </ul>
            </div>

            <h3 className="header text-4xl mb-10">
                Peripherals
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <ul className="text-left">
                    <li /> AOPEN Fire Legend 27HC2R Monitor
                    <li /> Magicforce Mechanical Mini (Gateron Blue switches)
                    <li /> Glorious Model O- Wired
                    <li /> HyperX Quadcast
                    <li /> Sony MDR-ZX110
                    <li /> Logitech C922
                </ul>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    While it was my first time building a PC, I did not hit any
                    snags (besides my own inexperience, naturally). Most of the
                    peripherals were various gadgets I had been building up
                    over time, but I figured I might as well mention them here
                    as well for completeness' sake. I will keep this page 
                    updated as time goes on.
                </p>
            </div>
        </div>
    );
}
