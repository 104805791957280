import ExternalLink from '../components/ExternalLink';
import { Link } from 'react-router-dom';
import DownArrow from '../components/DownArrow';

import VFoxThumbnail from '../assets/project-thumbnails/vfox.png';
import {ReactComponent as DCLogo} from '../assets/site-icons/discord-icon.svg';
import {ReactComponent as PlusSVG} from '../assets/plus-circle.svg';
import VFoxOne from '../assets/vfox1.png';
import VFoxTwo from '../assets/vfox2.png';
import VFoxThree from '../assets/vfox3.png';

function DiscordButton(props) {
    return (
        <div className="flex justify-center">
        <button type="button" className="bg-indigo-500 hover:bg-indigo-600 border-indigo-700 hover:border-indigo-800 border-4 transition-colors text-white text-3xl rounded-full p-16 py-4 w-80">
            <ExternalLink href={`https://${props.href}`} className="flex justify-center items-center">
                {props.children}
            </ExternalLink>
        </button>
        </div>
    );
}

export default function VFoxInvite() {
    return (<>
        <DownArrow />
        <div className="lg:box max-w-4xl mx-auto mt-16 lg:p-20">
        <div className="flex justify-center items-center m-auto">
            <img src={VFoxThumbnail} alt="" className="inline h-24 border-orange-500/75 border-4 rounded-3xl ml-8 mr-4 lg:mr-8">
            </img>
            <h1 className="header text-6xl lg:text-8xl inline text-left">
                VFox Bot
            </h1>
        </div>
        <div className="flex justify-center text-2xl lg:text-4x text-center mt-10">
            <p>
                A discord game about collecting foxes!
            </p>
        </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-8 mt-20 md:mx-20">
            <DiscordButton href="discord.com/api/oauth2/authorize?client_id=1003467309911900270&permissions=0&scope=bot">
                <PlusSVG className="inline h-10 mr-3"/>
                <p className="inline">
                    Invite
                </p>
            </DiscordButton>
            <DiscordButton href="discord.gg/mmzed2k3cw">
                <DCLogo className="invert inline h-10 mr-3"/>
                <p className="inline">
                    Support
                </p>
            </DiscordButton>
        </div>
        <div className="text-center text-red-500 mt-8">
            <h3>
                Due to a lack of interest, VFox is currently not online to conserve server resources.
            </h3>
            <h3>
                If you would like to play,
                <Link to="contact" className="mx-2 font-bold focus:underline hover:underline">
                    contact me
                </Link>
                and I can spin it up again super easily!
            </h3>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 mt-64">
            <div className="text-center lg:text-left">
                <h2 className="header">
                    Find Foxes
                </h2>
                <p className="mt-10">
                    Now introducing, the best way to find virtual
                    foxes on Discord! Fully humane, and fully fun.
                </p>
                <p className="mt-10">
                    Your progress stays between servers. So you can hunt
                    in private to impress your friends!
                </p>
            </div>
            <img src={VFoxOne} alt="" className="rounded-lg max-lg:mx-auto lg:ml-auto mt-12 mb-40" />

            <div className="lg:row-start-2 lg:col-start-2 text-center lg:text-right">
                <h2 className="header">
                    Acquire Upgrades
                </h2>
                <p className="mt-10">
                    Sacrifice foxes at the shrine for short-term buffs,
                    or sell them to purchase equipment for permanent boosts.
                </p>
                <p className="mt-10">
                    In addition, there are over 40 different single-use items
                    which can combo with everything else for even more options!
                </p>
            </div>
            <img src={VFoxTwo} alt="" className="lg:row-start-2 lg:col-start-1 rounded-lg max-lg:mx-auto lg:mr-auto mt-12 mb-40" />

            <div className="text-center lg:text-left">
                <h2 className="header">
                    And More
                </h2>
                <p className="mt-10">
                    Seriously, there is so much content in this bot.
                    Go check it out!
                </p>
            </div>
            <img src={VFoxThree} alt="" className="rounded-lg max-lg:mx-auto lg:ml-auto mt-12" />
        </div>
    </>);
}
