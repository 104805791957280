import ExternalLink from '../../components/ExternalLink';

export default function LetterGetter() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Letter Getter
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://letter-getter.foxsylv.dev" className="hover:underline focus:underline">
                    <p className="mb-5">
                        https://letter-getter.foxsylv.dev
                    </p>
                </ExternalLink>
                <p>
                    A daily grid-based anagram game
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    My primary objective was to enhance my familiarity with javascript
                    and React. I decided on making this game specfiically by
                    mashing together two word games that I enjoy: Bookworm Adventures,
                    and Wordle. Thus the result is a grid-based anagram game similar to
                    Bookworm Adventures, but also with the daily aspect of Wordle.
                </p>
                <p>
                    Whether or not I did a good job in making an entertaining game, I
                    don't really know. At the very least, I've recieved generally
                    positive feedback (including from people who are not my mom,
                    surprisingly), so I'll assume it's fine.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    The project is, effectively, just a mashup of React components with
                    some scss styling thrown in for flair to make everything look nice.
                    Along the way, however, there were a few fun surprises that I had
                    to learn. Notably, local storage for storing the daily submission
                    list across page reloads, for example.
                </p>
                <p>
                    I was originally planning on having the daily and practice modes
                    be located in separate pages, but then I looked at how that
                    would work in React with routing. I got confused, and decided
                    against that approach. Learning this aspect of development,
                    though, is my goal for next project! (unless it doesn't fit in
                    well, in which case I'll put it off further).
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p className="mb-5">
                    I think that, as of this point, I know how to make single page
                    apps quite well. I have the core fundamentals down (HTML, CSS,
                    JS) and know enough about React that I could make things work.
                    Granted, there's no guarantee that it would work <i>well</i>,
                    but it would work.
                </p>
                <p>
                    I'm not sure how much I will continue this project, since it
                    seems pretty finished to me. Unless I would like to do something
                    crazy later with it, I may just move on to new things, and see
                    what I can learn.
                </p>
            </div>
        </div>
    );
}
