import ExternalLink from '../../components/ExternalLink';

export default function HLP() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Hex Layer Problem
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://discord.com/invite/g9XF2wf" className="header text-3xl hover:underline focus:underline">
                    <p className="mb-5">
                        HLP Discord
                    </p>
                </ExternalLink>
                <p>
                    A brute-force algorithm for the Hex Layer Problem
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    The HLP is a question in Minecraft redstone that asks
                    how to optimally convert a hex line into a desired set
                    of signal strength outputs. Because I am a massive nerd,
                    this problem piqued my interest greatly.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p className="mb-5">
                    There is no "nice" algorithm, since a subset of the HLP is
                    equivalent to sorting by prefix reversals, which
                    <ExternalLink href="https://arxiv.org/abs/1111.0434v1" className="ml-2 hover:underline focus:underline">
                        has been proven to be NP-hard
                    </ExternalLink>.
                    As such, brute force is the only option, while desperately
                    clawing at optimizations to save time.
                </p>
                <p>
                    I coded the algorithm in C to maximize speed, and
                    implemented a novel way to store HLP functions to minimize
                    space needed for caching to the theoretical minimum.
                    This new approach plus other optimizations makes it
                    surprisingly fast.
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    While not perfect, I like my algorithm, if for no other reason
                    than because I made it. The HLP is a really interesting
                    problem to work on, even if a bit niche!
                </p>
            </div>
        </div>
    );
}
