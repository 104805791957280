import { useRouteError } from "react-router-dom";

export default function ErrorPage(props) {
    const error = useRouteError();
    var err = (props.is404 ? "404" : error.statusText);
    var msg = (props.is404 ? "Page not found" : error.message);

    return (<>
        <div className="text-center">
            <h1 className="header">
                An error has occurred!
            </h1>
            <h2 className="header text-3xl mb-40">
                ;w;
            </h2>
            <p className="box w-1/2 m-auto text-3xl">
                {err}
                <br />
                {msg}
            </p>
        </div>
    </>);
}
