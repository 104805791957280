import ExternalLink from '../../components/ExternalLink';

export default function Bearable() {
    return (
        <div className="text-center max-w-3xl m-auto">
            <h1 className="header mb-10">
                Bearable
            </h1>
            <div className="box px-[5vw] py-6 mb-32">
                <ExternalLink href="https://bearable.foxsylv.dev" className="hover:underline focus:underline">
                    <p className="mb-5">
                        https://bearable.foxsylv.dev
                    </p>
                </ExternalLink>
                <p>
                    Bowdoin Essential Academic Resource And Bulletin that Links Everywhere
                </p>
            </div>

            <h2 className="header text-4xl mb-10">
                Goals
            </h2>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    My main goal was honestly just to have one place that has all of
                    the essential links of Bowdoin, since the official pre-existing
                    dashboards were in their own way incomplete. My former friend Brooke made
                    a website called
                    <ExternalLink href="https://nuisance.breq.dev" className="mx-1 hover:underline focus:underline">
                        nuisance,
                    </ExternalLink>
                    which is the same idea but for Northeastern's links,
                    so I just yoinked the style and idea and said "it's Bowdoin time :3"
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Production Details
            </h3>
            <div className="box px-[5vw] py-10 mb-32">
                <p>
                    I borrowed (read: copied) the style from Brooke's
                    <ExternalLink href="https://nuisance.breq.dev" className="mx-1 hover:underline focus:underline">
                        nuisance,
                    </ExternalLink>
                    and making components in React to fit that vision was a
                    straightfoward process. 
                </p>
            </div>

            <h3 className="header text-4xl mb-10">
                Conclusion
            </h3>
            <div className="box px-[5vw] py-10">
                <p>
                    While this project was not particular ambitious in terms of
                    learning new techniques or technologies, I am still proud
                    of the result for the sole reason that it's a much nicer
                    dashboard to use. It's available for other people to use too
                    if they would like (but it so far has not been widely adopted).
                </p>
            </div>
        </div>
    );
}
